@import './src/styles';

.send-money {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 100px;

  .send-money-alert {
    max-width: 510px;
    align-items: center;
    margin-bottom: 30px;
  }

  .send-money-confirmation-alert {
    justify-content: center;
  }

  .send-money-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 510px;
    gap: 49px
  }

  .attach-doc-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 510px;
    gap: 30px;

    .attach-doc-input {
      width: 100%;
    }
  }

  .inputs-wrapper, .inputs-wrapper-evenly {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 30px;

    &.last {
      margin-top: 30px;
    }

    .disabled-input {
      .input {
        background-color: $light;
        border-color: $light;
      }
    }
  }

  .inputs-wrapper-evenly >:nth-child(n) {
    width: 240px;
    @media (max-width: 852px) {
      width: 100%;
    }
  }

  .send-transfer-button {
    height: 60px;
    width: fit-content !important;
    padding: 12px 18px;
    border-radius: 10px;
  }

  .save-transfer-button {
    height: 60px;
    width: 100%;
    padding: 12px 18px;
    border-radius: 10px;
  }

  .inputs-wrapper-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  .inputs-wrapper-flex > :last-child,
  .inputs-wrapper-flex > :first-child {
    min-width: 240px;
    flex: 1;
  }


  .inputs-wrapper > :last-child, .inputs-wrapper.Company > :first-child {
    flex: 1;
    .input {
      width: 100%;
      min-width: 150px;
    }
  }

  .inputs-wrapper > :first-child, .inputs-wrapper.Company > :last-child {
    flex: 2;
    .input {
      width: 100%;
      min-width: 330px;
    }
  }

  .inputs-wrapper.Individual > :nth-child(n) {
    flex: 1;
    .input {
      width: 100%;
      min-width: 150px;
    }
  }

  .transfer-details {
    input {
      padding-right: 16px;
    }
  }

  .navigation-payment {
    display: flex;
    align-self: flex-start;
    width: 100%;
    margin-top: -19px;
    height: 60px;

    .navigation-payment-button {
      padding: 20px 10px;
      border-bottom: 1px solid $light-neutral;
      width: 50%;
      white-space: nowrap;
      &:active, &.active {
        border-bottom: 1px solid $active-default;
      }
    }
  }
}

@include mobile {
  .send-money {
    
    .send-money-wrapper {
      margin-top: 20px;
    }

    .navigation-payment {
      display: flex;
      justify-content: flex-start;
      width: 100%;

      .navigation-payment-button {
        width: 50%;
        white-space: pre-wrap;
        padding: 20px 20px 20px 0;

        svg {
          min-width: 20px;
          min-height: 20px;
        }
      }
    }

    .inputs-wrapper, .inputs-wrapper-evenly {
      width: 100%;

      .send-transfer-button {
        width: 100% !important;
        padding: 12px 16px;
      }
    }

    .inputs-wrapper-evenly:last-of-type {
      flex-wrap: wrap;
    }

    .inputs-wrapper > :nth-child(n), .inputs-wrapper-evenly >:nth-child(n) {
      width: 100%;
    }

    .inputs-wrapper> :first-child,
    .inputs-wrapper.Company> :last-child {
      flex: 2;

      .input {
        width: 100%;
        min-width: 250px;
      }
    }
    .inputs-divider__text {
      background-color: $background-color;
    }
  }
}