@import 'src/styles';

.unapproved-payments {
  .unapproved-payments-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
  }

  .filters-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10px 0 20px 0;

    .filters-left {
      display: flex;
      gap: 16px;
    }
    
    .filters-right {
      display: flex;
      gap: 8px;
    }
  }

  .actions-container {
    position: absolute;
    top: 28px;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
    padding: 0 30px;

    .action-buttons {
      display: flex;
      gap: 16px;
      height: 44px;

      button {
        height: 44px;
        width: 100%;
        padding: 0 40px;
        @extend %body;
      }

      .delete-button {
        color: $error;
        border-color: $error;

        &:hover:not(:disabled) {
          background: rgba($error, 0.1);
        }

        &.disabled {
          color: $light-neutral !important;
          border-color: $light-neutral !important;
        }
      }
    }
  }

  .empty-prepared-transactions-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 24px;
    gap: 10px;

    .empty-prepared-transactions-header {
      @extend %heading-2;
      line-height: 24px;
      text-align: center;
      color: $header;
    }

    .empty-prepared-transactions-text {
      max-width: 280px;
      @extend %body;
      text-align: center;
      color: $bg-secondary;
    }
  }
}

@include mobile {
  .unapproved-payments {
    max-width: 499px;

    .actions-container {
      top: unset;
      right: unset;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 10px 0 24px 0;
      padding: 0 24px;

      .action-buttons {
        display: flex;
        gap: 16px;
        height: 44px;
      }

      .delete-button {
        width: 125px;
      }

      .approve-button {
        width: 125px;
      }
    }
  }
}