@import 'src/styles.scss';

.payment-confirmation-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.payment-confirmation-card {
  width: 100%;
  max-width: 650px;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $bg-light;
  //box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 24px;

}

.payment-section {
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.section-title {
  @extend %heading-2;
  color: $header;
  margin-bottom: 12px;
}

.payment-detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.payment-detail-label {
  color: $neutral;
  font-weight: 400;
  margin-right: 24px;
  min-width: 120px;
  @extend %body;
}

.payment-detail-value {
  color: $dark;
  font-weight: 400;
  text-align: right;
  @extend %body;
  word-break: break-all;
}

.payment-actions {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  max-width: 600px;

  button {
    height: 44px;
    border-radius: 8px;
    font-size: $font-size-normal;
    font-weight: 500;
    transition: all 0.3s ease;
    min-width: 120px;
    width: 160px;
  }

  .edit-button {
    background-color: $white;
    border: 1px solid $active-default;
    color: $active-default;

    &:hover {
      background-color: rgba($active-default, 0.05);
    }
  }

  .confirm-button {
    background-color: $active-default;
    border: none;
    color: $white;
  }
}

.section-divider {
  height: 1px;
  background-color: rgba($neutral, 0.1);
  margin: 16px 0;
}

.amount-highlight {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

@include mobile {
  .payment-confirmation-container {
    padding: 16px;
  }

  .payment-confirmation-card {
    padding: 16px;
  }

  .payment-actions {
    width: 100%;
    padding: 0;
    
    button {
      margin: 0 auto;
      max-width: 160px;
      width: 100%;
    }
  }
}
