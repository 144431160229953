@import 'src/styles';

.unapproved-payments-filters {
  width: 100%;
  margin: 10px 0 24px 0;
  padding: 0 26px;

  .filter-group {
    display: flex;
    align-items: flex-end;
    width: 100%;
    gap: 20px;

    .unapproved-payments-filter-input {
     height: 60px;

      input {
        height: 60px;
      }
    }

    .currency {
      width: 180px;
      box-sizing: border-box;

      input {
        width: 180px;
        min-width: unset;
        box-sizing: border-box;
      }
    }

    .wallet {
      width: 260px;
      box-sizing: border-box;

      input {
        width: 260px;
        min-width: unset;
        box-sizing: border-box;
      }
    }

    .date-filter {
      min-width: 235px;
      max-width: 235px;

      .label {
        color: $header !important;
      }
    }

    .search-text-input {
      width: 100%;
      min-width: 190px;

      .input-icon {
        top: 22.5px;
      }
    }
  }
}

@include mobile {
  .unapproved-payments-filters {
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;

    .filter-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      width: 100%;
      gap: 20px;
      box-sizing: border-box;

      .unapproved-payments-filter-input {
        width: 100%;

        input {
          width: 100%;
        }
      }

      .currency {
        width: 100%;

        input {
          width: 100%;
        }
      }

      .wallet {
        width: 100%;

        input {
          width: 100%;
        }
      }

      .date-filter {
        width: 100%;
        max-width: unset;
        min-width: unset;

      }

      .search-text-input {
        width: 100%;
        min-width: unset;
      }
    }
  }
}