@import 'src/styles';

.unapproved-payments-table {
  width: 100%;
  background: $white;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 20px;

  .table-header {
    display: grid;
    grid-template-columns: 60px 160px 1fr 1.5fr 160px 40px;
    padding: 16px 24px;
    border-bottom: 1px solid $bg-border;
    
    > div {
      @extend %caption;
      color: $dark-neutral;
      font-weight: 500;
    }

    .edit-cell {
      justify-content: flex-start;
    }
  }

  .table-body {
    .table-row {
      display: grid;
      grid-template-columns: 60px 160px 1fr 1.5fr 160px 40px;
      padding: 16px 24px;
      align-items: center;

      &.selected {
        background-color: rgba($active-default, 0.05);
      }

      &:hover {
        background-color: $bg-2;
      }

      > div {
        @extend %body;
        color: $dark;
      }

      .edit-icon {
        cursor: pointer;
        color: $dark-neutral;

        &:hover {
          color: $active-hover;
        }
      }
    }
  }

  .payee-cell {
    word-break: break-all;
  }

  .payee-cell, .purpose-cell, .amount-cell {
    padding-right: 24px;
  }

  .checkbox-cell {
    display: flex;
    align-items: center;
    padding: 10px 24px 10px 10px;

    .dropdown-choice {
      padding: 0;
    }
    
    input[type="checkbox"] {
      width: 16px;
      height: 16px;
      border: 1px solid $bg-border;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .amount-cell {
    text-align: left;
  }

  .edit-cell {
    display: flex;
    text-align: left;
    justify-content: flex-end;
  }
}

@include mobile {
  .unapproved-payments-table {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 24px 24px 24px;
    margin-top: 10px;

    .unapproved-transaction-item-mobile {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 20px;
      gap: 12px;
      width: 100%;
      border: 1px solid $light-neutral;
      border-radius: 8px;

      &.selected {
        background-color: rgba($active-default, 0.05);
      }

      .unapproved-transaction-item-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 20px;

        .dropdown-choice {
          padding: 0;
        }

        input[type="checkbox"] {
          width: 16px;
          height: 16px;
          border: 1px solid $bg-border;
          border-radius: 4px;
          cursor: pointer;
        }

        .icon-button {
          svg {
            margin-right: 0;
          }
        }
      }

      .unapproved-transaction-item-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 20px;

        .unapproved-transaction-item-head-label {
          max-width: 100px;
          @include annotation();
          line-height: 16px;
          color: $dark-neutral;
        }

        .unapproved-transaction-item-cell-content {
          max-width: 160px;
          word-break: break-all;
          @include annotation();
          line-height: 16px;
          color: #1E1E1E;
        }
      }
    }
  }
}